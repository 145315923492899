<template>
  <section class="eLearningDetail">
    <div class="eLearningDetail__banner">
      <div class="eLearningDetail__bannerCarousel">
        <carousel
          :perPage="1"
          :autoplay="true"
          :autoplayHoverPause="true"
          :paginationEnabled="false"
          :navigationEnabled="true"
          :navigationNextLabel="''"
          :navigationPrevLabel="''"
        >
          <slide v-for="img in detailItem.image" :key="img">
            <div
              class="eLearningDetail__carouselImg"
              :style="`background-image: url(${img});`"
            ></div>
          </slide>
        </carousel>
      </div>
      <div class="eLearningDetail__bannerRightContainer">
        <div class="eLearningDetail__bannerRight">
          <h2 class="eLearningDetail__bannerTitle">
            {{ detailItem.title }}
          </h2>
          <p class="eLearningDetail__bannerDesc">
            <!-- {{ detailItem.year }}年 -->
            {{ detailItem.grade }}
          </p>
          <img
            src="@/assets/image/ELearning/ELearningDetailMountain.png"
            alt=""
            class="eLearningDetail__bannerRightImage"
          />
        </div>
      </div>
    </div>

    <div class="eLearningDetail__textContainer container">
      <span class="eLearningDetail__circle"></span>
      <div class="eLearningDetail__introduction">
        <h3
          class="eLearningDetail__imageTitle eLearningDetail__imageTitle--introduction"
        >
          教案介紹
        </h3>
        <p style="word-break: break-all;">
          {{ detailItem.intro }}
        </p>
      </div>

      <div class="eLearningDetail__design">
        <h3
          class="eLearningDetail__imageTitle eLearningDetail__imageTitle--design"
        >
          設計理念
        </h3>
        <p style="word-break: break-all;">
          {{ detailItem.design }}
        </p>
      </div>
      <a
        :title="`下載${detailItem.title}教案pdf`"
        :href="detailItem.file"
        class="eLearningDetail__download clickable"
        download
      ></a>
    </div>

    <GoBackFooter
      :routeItem="{
        text: '返回數位學習',
        arrowString: '&#10229;',
        routeObj:
          type === 'junior'
            ? { name: 'ELearningJunior' }
            : { name: 'ELearningElementary' }
      }"
    />
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import GoBackFooter from '@/components/GoBackFooter.vue'

import { apiGetELearning } from '@/api/webAPI'

export default {
  components: { Carousel, Slide, GoBackFooter },
  data() {
    return {
      detailItem: {
        design: '',
        file: '',
        grade: '',
        intro: '',
        title: '',
        year: ''
      },
      type: ''
    }
  },
  mounted() {},
  async created() {
    const type = this.$route.query.type
    this.type = type
    const title = this.$route.query.title
    if (!type || !title) {
      this.$router.push({ name: 'ELearning' })
      return
    }
    const eLearningResponse = await apiGetELearning(type)
    const elementaryLists = eLearningResponse.data.data
    const item = elementaryLists.find(el => el.title === title)
    if (!item) {
      this.$router.push({ name: 'ELearning' })
      return
    }
    this.detailItem = item
  }
}
</script>

<style lang="scss">
.eLearningDetail {
  &__banner {
    display: flex;
  }
  &__bannerCarousel {
    width: 50%;
    position: relative;
  }

  & .VueCarousel-navigation {
    position: absolute;
    right: 4rem;
    bottom: 2.2rem;
  }

  & .VueCarousel-navigation-button {
    width: 3rem;
    height: 3rem;
    border: 2px solid #d0a805;
    color: #d0a805;
    padding: 0 !important;
  }

  & .VueCarousel-navigation-prev {
    transform: translateY(-50%) translateX(-130%);
    background-image: url('~@/assets/image/ELearning/ArrowLeft.png');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
  }

  & .VueCarousel-navigation-next {
    background-image: url('~@/assets/image/ELearning/ArrowRight.png');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
  }

  &__carouselImg {
    width: 100%;
    padding-bottom: 55.55%;
    // height: 0;
    // background-image: url('~@/assets/image/ELearning/ELearningDetailBannerLeft.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__bannerRightContainer {
    width: 50%;
    padding-bottom: 25%;
    position: relative;
  }

  &__bannerRight {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('~@/assets/image/ELearning/ELearningDetailBannerRight.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    // position: relative;
  }

  &__bannerTitle::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-image: url('~@/assets/image/TitleIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 3.8rem;
  }
  &__bannerTitle {
    position: relative;
    font-weight: 500;
    font-size: 3.1rem;
    line-height: 124%;
    letter-spacing: 0.18em;
    color: #595757;
    padding-left: 5.3rem;
    margin-top: 10%;
    margin-left: 5%;
  }

  &__bannerDesc {
    padding-left: 5.3rem;
    margin-left: 5%;
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 124%;
    color: #595757;
  }

  &__titleIcon {
    background-image: url('~@/assets/image/TitleIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 3.8rem;
    height: 2.55rem;
    padding-bottom: 67.8%;
    margin-right: 1.8rem;
  }

  &__bannerRightImage {
    position: absolute;
    bottom: 0;
    right: 5rem;
    width: 50%;
    height: auto;
  }

  &__textContainer {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-top: 4.5rem;
    margin-bottom: 13.2rem;
  }

  &__circle {
    background-color: #d0a805;
    height: 9rem;
    width: 9rem;
    border-radius: 999em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
  }

  &__introduction,
  &__design {
    width: 50%;
    padding: 10rem;
    padding-top: 0;
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: justify;
    white-space: pre-line;
  }

  &__introduction {
    border-right: 1px dashed #d0a805;
  }

  &__imageTitle {
    width: 17.8rem;
    height: 6.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 4.5rem;
  }

  &__imageTitle--introduction {
    background-image: url('~@/assets/image/ELearning/ELearningDetailIntroduction.png');
  }

  &__imageTitle--design {
    background-image: url('~@/assets/image/ELearning/ELearningDetailDesign.png');
  }

  &__download {
    width: 9.2rem;
    height: 9.2rem;
    background-image: url('~@/assets/image/ELearning/Download.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  }
}

@media (max-width: 768px) {
  .eLearningDetail {
    &__banner {
      flex-wrap: wrap;
    }

    &__bannerRight,
    &__bannerCarousel {
      width: 100%;
    }

    &__bannerRightContainer {
      width: 100%;
      padding-bottom: 55.55%;
    }

    &__introduction,
    &__design {
      width: 100%;
      padding: 0;
      padding: 3.2rem 0;
    }

    &__introduction {
      border: none;
      border-bottom: 1px dashed #d0a805;
    }
  }
}
</style>
