var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"eLearningDetail"},[_c('div',{staticClass:"eLearningDetail__banner"},[_c('div',{staticClass:"eLearningDetail__bannerCarousel"},[_c('carousel',{attrs:{"perPage":1,"autoplay":true,"autoplayHoverPause":true,"paginationEnabled":false,"navigationEnabled":true,"navigationNextLabel":'',"navigationPrevLabel":''}},_vm._l((_vm.detailItem.image),function(img){return _c('slide',{key:img},[_c('div',{staticClass:"eLearningDetail__carouselImg",style:(`background-image: url(${img});`)})])}),1)],1),_c('div',{staticClass:"eLearningDetail__bannerRightContainer"},[_c('div',{staticClass:"eLearningDetail__bannerRight"},[_c('h2',{staticClass:"eLearningDetail__bannerTitle"},[_vm._v(" "+_vm._s(_vm.detailItem.title)+" ")]),_c('p',{staticClass:"eLearningDetail__bannerDesc"},[_vm._v(" "+_vm._s(_vm.detailItem.grade)+" ")]),_c('img',{staticClass:"eLearningDetail__bannerRightImage",attrs:{"src":require("@/assets/image/ELearning/ELearningDetailMountain.png"),"alt":""}})])])]),_c('div',{staticClass:"eLearningDetail__textContainer container"},[_c('span',{staticClass:"eLearningDetail__circle"}),_c('div',{staticClass:"eLearningDetail__introduction"},[_c('h3',{staticClass:"eLearningDetail__imageTitle eLearningDetail__imageTitle--introduction"},[_vm._v(" 教案介紹 ")]),_c('p',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.detailItem.intro)+" ")])]),_c('div',{staticClass:"eLearningDetail__design"},[_c('h3',{staticClass:"eLearningDetail__imageTitle eLearningDetail__imageTitle--design"},[_vm._v(" 設計理念 ")]),_c('p',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.detailItem.design)+" ")])]),_c('a',{staticClass:"eLearningDetail__download clickable",attrs:{"title":`下載${_vm.detailItem.title}教案pdf`,"href":_vm.detailItem.file,"download":""}})]),_c('GoBackFooter',{attrs:{"routeItem":{
      text: '返回數位學習',
      arrowString: '&#10229;',
      routeObj:
        _vm.type === 'junior'
          ? { name: 'ELearningJunior' }
          : { name: 'ELearningElementary' }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }